import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {SessionContextProvider} from "./session";
import { ApolloProvider } from '@apollo/client/react';
import createApolloClient from './gql/client';
import { LicenseManager } from  'ag-grid-enterprise';
// Verificar licença vigente e preencher no setLicenseKey antes do deploy
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-067791}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Itaueira_Agropecuaria_S/A}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Itaueira}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Itaueira}_need_to_be_licensed___{Itaueira}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{7_December_2025}____[v3]_[01]_MTc2NTA2NTYwMDAwMA==6072ddb8a92315e974e182a71e428d56");

const client = createApolloClient();

ReactDOM.render(
  <React.StrictMode>
    <SessionContextProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </SessionContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);